<template>
  <div class="user-avatar-dropdown">
    <Dropdown @on-click="handleClick">
        <!--头像-->
        <!--<Avatar :src="userAvatar"/>-->
        {{trueName}}
      <Icon :size="18" type="md-arrow-dropdown"></Icon>
      <DropdownMenu slot="list">
        <DropdownItem name="message">
          个人信息
        </DropdownItem>
        <DropdownItem name="logout">退出登录</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import './user.less'
import { mapActions } from 'vuex'
export default {
  name: 'User',
  props: {
      //  头像
    // userAvatar: {
    //   type: String,
    //   default: ''
    // },
    messageUnreadCount: {
      type: Number,
      default: 0
    },
      trueName: {
          type: String,
          default: ''
      }
  },
  methods: {
    ...mapActions([
      'handleLogOut'
    ]),
    logout () {
        sessionStorage.clear();
        this.$router.push({
            name: 'login'
        })
    },
    message () {
      // this.$router.push({
      //   name: 'message_page'
      // })
    },
    handleClick (name) {
      switch (name) {
        case 'logout': this.logout()
          break
        case 'message': this.message()
          break
      }
    }
  }
}
</script>
