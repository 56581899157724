<template>
    <div class="layout">
        <Layout>
            <Header :style="{position: 'fixed', zIndex: 999, width: '100%', background: '#2685F6'}">
                <div class="layout-logo"><img src="../assets/logo.png" style="height: 40px; width: auto"></div>
                <div class="layout-title">脑健康家小程序管理系统</div>

                <div class="logout">
                    <!-- :user-avatar="userAvatar"  头像-->
                    <user  :trueName="trueName"/>
                    <fullscreen v-model="isFullscreen" style="margin-left: 10px;"/>

                    <!--                        <Avatar style="background-color: #87d068" size="small" icon="ios-person" />-->
                    <!--                        <span style="padding-left: 5px; padding-right: 15px">{{trueName}} </span>-->
                    <!--                        <a href="javascript:void(0)" @click="loginOut">-->
                    <!--                            <Icon type="ios-exit-outline" /> 退出-->
                    <!--                        </a>-->
                </div>
            </Header>
            <Layout style="height:calc(100vh - 64px); margin-top: 64px;">
                <Sider hide-trigger :style="{background: '#fff', overflow: 'auto'}">
                    <Menu active-name="113-1" theme="light" width="auto" :open-names="['113']">

                        <Submenu name="113">
                            <template slot="title">
                                <img src="../assets/img/patientIcon.png" alt="">
                                用户管理
                            </template>
                            <MenuItem name="113-1" to="/main/users/list">用户列表</MenuItem>
                        </Submenu>
                        <Submenu name="114">
                            <template slot="title">
                                <img src="../assets/img/patientIcon.png" alt="">
                                训练管理
                            </template>
                            <MenuItem name="114-1" to="/main/train/list">训练列表</MenuItem>
                        </Submenu>
                        <Submenu name="115">
                            <template slot="title">
                                <img src="../assets/img/patientIcon.png" alt="">
                                订单管理
                            </template>
                            <MenuItem name="115-1" to="/main/order/list">订单列表</MenuItem>
                        </Submenu>
                    </Menu>
                </Sider>
                <Layout style="height:calc(100vh - 64px); overflow: scroll">
                    <Layout :style="{margin: '24px 24px', padding: '12px 24px', background: '#fff', height: '100%', overflow: 'auto'}">
                        <router-view></router-view>
                    </Layout>
                </Layout>
            </Layout>
        </Layout>
    </div>

</template>
<script>
    import { clearCookie, getCookieTNAME } from '@js/session.sg';
    import Fullscreen from '../components/fullscreen'
    import User from '../components/user'

    export default {
        components: {
            User,
            Fullscreen
        },
        data() {
            return {
                // userAvatar: require('../assets/headimg.png'), 头像
                trueName: getCookieTNAME(),
                isFullscreen: false
            }
        },
        methods: {
            loginOut: function () {
                clearCookie();
                this.$router.push({ path: '/' });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .layout {
        /*border: 1px solid #d7dde4;*/
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .ivu-layout-header {
        padding: 0 15px;
    }
    .layout-logo{
        /*width: 100px;*/
        /*height: 30px;*/
        /*background: #5b6270;*/
        /*border-radius: 3px;*/
        /*float: left;*/
        /*position: relative;*/
        /*top: 15px;*/
        /*left: 20px;*/

        color: #fff;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 10px;
        left: 20px;
        font-weight: bold;
    }
    .layout-title {
        color: #fff;
        line-height: 30px;
        font-size: 25px;
        height: 30px;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 15px;
        left: 20px;
        font-weight: bold;
        margin-left: 5px;
    }

    .layout-nav {
        width: 420px;
        margin: 0 auto;
        margin-right: 20px;
    }
    .logout {
        float: right;
        color: #fff;
        font-size: 17px;
        display: flex;
        line-height: 64px;
    }
    .ivu-menu-submenu-title{
        display: flex;
        align-items: center;
        img{
            width: 12px;
            height: 12px;
            margin-right: 10px;
        }
    }
</style>
